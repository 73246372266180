import React, {useEffect, useRef} from 'react';
import {FormProvider, useForm as useRHForm} from 'react-hook-form';
import PropTypes from 'prop-types';

export const useForm = (data, errors, setDefaultValues = true) => {
    const firstRender = useRef(true);

    const methods = useRHForm({
        defaultValues: setDefaultValues ? data : undefined,
        shouldUnregister: true, // false causes all values to be sent, but not only those on the form
    });
    const {reset, setError} = methods;
    const errorsString = JSON.stringify(errors);

    useEffect(() => {
        reset(data);
    }, [data, reset]);

    // error mapping
    useEffect(() => {
        if (typeof errors !== 'object' || errors === null) {
            return;
        }

        for (const key of Object.keys(errors)) {
            setError(key, {type: 'manual', message: errors[key]}, {shouldFocus: true});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorsString, setError]);

    // reset data on first render
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        reset(data);
    }, [data, reset]);

    return {methods};
};

useForm.propTypes = {
    data: PropTypes.object,
    errors: PropTypes.array,
    setDefaultValues: PropTypes.bool,
};

const Form = ({onSubmit, methods, children, ...rest}) => {
    const doSubmit = (e) => {
        e.preventDefault();
        methods.clearErrors();
        return methods.handleSubmit(data => onSubmit(data))();
    };

    const keyDown = (e) => {
        if (e.key === 'Enter') {
            // causes problems with textareas
            //doSubmit(e);
        }
    };

    return <FormProvider {...methods}>
        <form autoComplete="off" noValidate onSubmit={doSubmit} onKeyDown={keyDown} {...rest}>
            {children}
        </form>
    </FormProvider>;
};

Form.propTypes = {
    onSubmit: PropTypes.func,
    methods: PropTypes.object.isRequired,
    children: PropTypes.any,
};

export default Form;
