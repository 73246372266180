import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import PropTypes from 'prop-types';

import {TextField} from '@mui/material';

import {useTranslation} from 'hooks';

const SelectField = ({name, defaultValue, label, placeholder, children, helperText, maxWidth, onChange, ...rest}) => {
    const {control} = useFormContext();

    const translate = useTranslation();

    const outerOnChange = onChange;

    return <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        shouldUnregister={true}
        render={({field: {onChange, onBlur, name, value, ref}, fieldState: {error}}) => {
            const v = value === undefined || value === null ? '' : value;
            return <TextField
                select
                name={name}
                onBlur={onBlur}
                ref={ref}
                variant="outlined"
                size="medium"
                value={v}
                error={!!error?.message}
                label={translate(label)}
                InputLabelProps={{shrink: true}}
                placeholder={translate(placeholder)}
                helperText={error?.message || translate(helperText)}
                onChange={e => {
                    outerOnChange && outerOnChange(e);
                    onChange(e);
                }}
                fullWidth={true}
                sx={{maxWidth}}
                {...rest}>
                {children}
            </TextField>;
        }}
    />;
};

SelectField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValue: PropTypes.any,
    placeholder: PropTypes.string,
    helperText: PropTypes.string,
    children: PropTypes.node,
};
export default SelectField;
