import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import PropTypes from 'prop-types';

import {TextField} from '@mui/material';

import {useKeyboardStore} from 'store';

import {getKeyboardOptions} from '../components';
import {useTranslation} from '../hooks';

const FormField = ({
    name,
    as,
    defaultValue,
    label,
    placeholder,
    onChange,
    helperText,
    maxWidth,
    readOnly,
    inputProps = {},
    ...rest
}) => {
    const {control} = useFormContext();
    const translate = useTranslation();

    const showKeyboard = useKeyboardStore(state => state.show);
    const hideKeyboard = useKeyboardStore(state => state.hide);

    const InnerElement = as ? as : TextField;
    const outerOnChange = onChange;

    const onInputFocus = (e, setVal) => {
        e.target.select();
        const opts = {};

        if (rest.type === 'number') {
            opts.layout = 'numpad';
            opts.left = -180;
            opts.maxLength = inputProps.maxLength;
        }

        showKeyboard(getKeyboardOptions(e, setVal, opts));
    };

    const onInputBlur = (onBlur) => {
        onBlur();
        hideKeyboard();
    };

    const onInputChange = (e, onChange) => {
        outerOnChange && outerOnChange(e);
        onChange(e);
    };

    return (<Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        shouldUnregister={true}
        render={({field: {onChange, onBlur, value, name, ref}, fieldState: {error}}) => {
            const v = value === undefined || value === null ? '' : value;
            return (<InnerElement
                name={name}
                variant="outlined"
                size="medium"
                value={v}
                error={!!error?.message}
                label={translate(label)}
                placeholder={translate(placeholder)}
                helperText={error?.message || translate(helperText)}
                inputRef={ref}
                onBlur={() => onInputBlur(onBlur)}
                onFocus={e => onInputFocus(e, onChange)}
                onChange={e => onInputChange(e, onChange)}
                inputProps={{...inputProps, readOnly}}
                InputLabelProps={{shrink: true}}
                fullWidth={true}
                sx={{maxWidth}}
                {...rest}/>);
        }}
    />);
};

FormField.propTypes = {
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
    onChange: PropTypes.func,
    as: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
    label: PropTypes.string,
    helperText: PropTypes.string,
    placeholder: PropTypes.string,
};

export default FormField;
