import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import PropTypes from 'prop-types';

import {FormControl, FormHelperText, TextField} from '@mui/material';
import {DesktopDatePicker} from '@mui/x-date-pickers';

import {useTranslation} from 'hooks';

import {dateFormat} from '../lib/datefns';

const DISPLAY_FORMAT = 'dd.LL.yyyy';
const VALUE_FORMAT = 'yyyy-LL-dd';

const DateField = ({name, defaultValue, label, helperText, fullWidth, ...rest}) => {
    const {control} = useFormContext();
    const translate = useTranslation();

    return (<Controller
        control={control}
        name={name}
        shouldUnregister={true}
        defaultValue={defaultValue}
        render={({field: {onChange, onBlur, name, value}, fieldState: {error}}) => {
            return (<FormControl fullWidth={fullWidth} error={!!error?.message}>
                <DesktopDatePicker
                    name={name}
                    label={translate(label)}
                    autoOk={true}
                    inputFormat={DISPLAY_FORMAT}
                    value={value}
                    onBlur={onBlur}
                    onChange={v => {
                        if (typeof v === 'object') {
                            v = dateFormat(v, VALUE_FORMAT);
                        }

                        onChange(v);
                    }}
                    renderInput={(params) => <TextField size="small" variant="outlined" {...params} />}
                    {...rest}
                />
                <FormHelperText>{error?.message || translate(helperText)}</FormHelperText>
            </FormControl>);
        }}
    />);
};

DateField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValue: PropTypes.any,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.string,
};

export default DateField;
