import React from 'react';

import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Table, TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';

const Route = ({route}) => {
    return <TableRow>
        <TableCell align="left">{route.dst}</TableCell>
        <TableCell align="left">{route.gateway}</TableCell>
        <TableCell align="left">{route.dev}</TableCell>
        <TableCell align="left">{route.prefsrc}</TableCell>
        <TableCell align="left">{route.scope}</TableCell>
        <TableCell align="left">{route.metric}</TableCell>
        <TableCell align="left">{route.protocol}</TableCell>
    </TableRow>;
};

const Devices = ({data}) => {
    if (!Array.isArray(data)) {
        return null;
    }

    return <Table sx={{minWidth: 650}} size="small">
        <TableHead>
            <TableRow>
                <TableCell align="center" sx={{fontWeight: '600'}}>dst</TableCell>
                <TableCell align="center" sx={{fontWeight: '600'}} width={140}>gateway</TableCell>
                <TableCell align="center" sx={{fontWeight: '600'}} width={160}>dev</TableCell>
                <TableCell align="center" sx={{fontWeight: '600'}} width={160}>src</TableCell>
                <TableCell align="center" sx={{fontWeight: '600'}} width={80}>scope</TableCell>
                <TableCell align="center" sx={{fontWeight: '600'}} width={80}>metric</TableCell>
                <TableCell align="center" sx={{fontWeight: '600'}} width={100}>protocol</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {data.map((r, idx) => <Route key={idx} route={r}/>)}
        </TableBody>
    </Table>;
};

const NetRoutes = ({data}) => {
    return <Card>
        <CardHeader title="Routes"/>
        <Divider/>
        <CardContent>
            <Devices data={data}/>
        </CardContent>
    </Card>;
};

export default NetRoutes;
