import React from 'react';

import {Grid} from '@mui/material';

import {TeamDetail} from 'module/settings';
import {useActivateTeam} from 'module/settings/useSettings';

const styles = {
    teamBlock: {
        background: 'linear-gradient(180deg, rgba(41,41,41,1), rgba(73,73,73,1));',
        padding: '30px 20px 0 20px',
        width: '100%',
        zIndex: 999,
    },
};

const TeamsDetails = ({team}) => {
    const activateTeam = useActivateTeam();

    const doSelect = (teamData) => {
        activateTeam(teamData);
    };

    return <Grid item sx={styles.teamBlock}>
        <TeamDetail
            team={team}
            onSelect={doSelect}
        />
    </Grid>;
};

export default TeamsDetails;
