import React from 'react';

import {Box, Radio, Stack} from '@mui/material';

import {Text} from 'components';
import {languages,useSettingsStore} from 'module/settings/zustand';

const styles = {
    boxContainer: {
        width: '100%',
    },
    box: {
        height: 70,
        marginBottom: '1px',
        color: 'white.main',
        fontSize: '16px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        WebkitBoxAlign: 'center',
        alignItems: 'center',
        padding: '0 0 0 20px',
        backgroundColor: 'grey.darker',
    },
    radioButton: {
        width: '70px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
};

const Language = () => {
    const selectedLanguage = useSettingsStore((store) => store.selectedLanguage);
    const setSelectedLanguage = useSettingsStore((store) => store.setSelectedLanguage);

    const handleChange = (code) => {
        setSelectedLanguage(code);
    };

    return (
        <Box sx={styles.boxContainer}>
            {languages.map((language, i) => (
                <Box key={`panelgroup-${i}`}>
                    <Box
                        sx={styles.box}
                        aria-controls={`panel3bh-content${i}`}
                        onClick={() => handleChange(language.code)}
                    >
                        <Text>{language.name}</Text>
                        <Stack sx={styles.radioButton}>
                            <Radio
                                checked={language.code === selectedLanguage}
                                value={`panel${i}`}
                                name="teams"
                                sx={{
                                    color: 'white.main',
                                    '&.Mui-checked': {
                                        color: 'green.main',
                                    },
                                }}
                            />
                        </Stack>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default Language;
