import {useCallback} from 'react';

import {sizeConverter} from 'canvas/Drawer/player-utils';
import {usePrefsStore} from 'store';

import {useSettingsStore} from './zustand';

export const getPlayerNameNumberFromSettings = (teamPlayer, from) => {
    const emptyChar = '';

    switch (from) {
    case 'name':
        return teamPlayer.firstname || emptyChar;
    case 'number':
        return `${teamPlayer.number || emptyChar}`;
    case 'initials':
        return `${teamPlayer.firstname?.substr(0, 1) || emptyChar}${teamPlayer.lastname?.substr(0, 1) || emptyChar}`;
    default:
        return emptyChar;
    }
};

export const useApplyChangesToToolbar = () => {
    const setTool = usePrefsStore((state) => state.setTool);
    const setHomeTeam = usePrefsStore((state) => state.setHomeTeam);
    const setSettingsActive = useSettingsStore((state) => state.setActive);

    return useCallback((teamData) => {
        setSettingsActive(true);
        setHomeTeam(teamData?.prefs?.direction === 'home');
        setTool(teamData?.prefs?.player?.type, {size: sizeConverter[teamData?.prefs?.player?.size]});
        setTool(teamData?.prefs?.player?.type, {picture: teamData?.prefs?.player?.image});

        // setTool('playerCircle', {size: sizeConverter[teamDetails?.prefs?.player?.size]});
        if (teamData?.prefs?.player?.type === 'player') {
            setTool(teamData?.prefs?.player?.type, {
                color: {
                    name: 'custom',
                    value1: teamData?.prefs?.player?.color1,
                    value2: teamData?.prefs?.player?.color2,
                    textColor: 'white.main',
                }
            });
        } else {
            setTool(teamData?.prefs?.player?.type, {
                name: 'custom',
                color: teamData?.prefs?.player?.color1,
                textColor: 'white.main'
            });
        }
    }, [setHomeTeam, setSettingsActive, setTool]);
};
