import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import PropTypes from 'prop-types';

import {Autocomplete as MatAutocomplete,TextField} from '@mui/material';

import {useTranslation} from 'hooks';
// import {makeStyles} from "@mui/styles";

// const useStyles = makeStyles((theme) => ({
//     helper: {
//         // fontSize: 12,
//         // color: theme.palette.text.secondary,
//         // paddingLeft: 16,
//         marginTop: 0,
//         marginLeft: 14,
//         marginRight: 14,
//         marginBottom: 10,
//         lineHeight: 1.3
//     }
// }));

const AutocompleteField = ({
    name,
    label,
    defaultValue,
    placeholder,
    fullWidth,
    mapValue,
    required,
    onChange,
    helperText,
    maxWidth,
    ...rest
}) => {
    const {control} = useFormContext();

    const translate = useTranslation();

    const outerOnChange = onChange;

    return <Controller
        control={control}
        name={name}
        shouldUnregister={true}
        defaultValue={defaultValue}
        render={({field: {onChange, onBlur, value, name, ref}, fieldState: {error}}) => {
            if (rest.multiple && !Array.isArray(value)) {
                value = value ? [value] : [];
            }

            return <MatAutocomplete
                name={name}
                value={value}
                color="primary"
                autoHighlight={true}
                onBlur={onBlur}
                size="small"
                ref={ref}
                handleHomeEndKeys={false} // otherwise home/end inside input is not working
                renderInput={params => {
                    return <TextField
                        fullWidth={fullWidth === undefined ? true : fullWidth}
                        error={!!error?.message}
                        size="medium"
                        InputLabelProps={{shrink: true}}
                        helperText={error?.message || translate(helperText)}
                        variant="outlined"
                        label={translate(label)}
                        placeholder={translate(placeholder)}
                        required={required}
                        {...params}
                    />;
                }}
                onChange={(_e, data) => {
                    let v;

                    if (Array.isArray(data)) { // multiple
                        v = data.map(d => mapValue ? mapValue(d) : d);
                    } else {
                        v = mapValue ? mapValue(data) : data;
                    }

                    outerOnChange && outerOnChange(v, data);
                    onChange(v);
                }}
                fullWidth={true}
                sx={{maxWidth}}
                {...rest}/>;
        }}
    />;
};

AutocompleteField.propTypes = {
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    defaultValue: PropTypes.any,
    mapValue: PropTypes.func,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
};
export default AutocompleteField;

// https://github.com/react-hook-form/react-hook-form/issues/681
// https://codesandbox.io/s/react-hook-form-controller-079xx
